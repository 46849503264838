$secondary: #878e97;
$background: #0e0e0e;
$text: #ffffff;
$brand-50: #e08821aa;
$primary: #e08821;
$brand: #e08821;
$brand-2: #e08821; //same
$brand-800: #e08821;
$font-size-base: 0.9rem;
$sidewidth: 250px;

@import "~bootstrap/scss/bootstrap.scss";

.App {
  position: relative;
  min-height: 100vh;
  // background: rgba(224, 136, 33, 0.141176);
  // background: radial-gradient(rgba(224, 136, 33, 0.141176), $background);
}

.app-container {
  display: flex;

  .sidebar {
    transition: all 0.3s;
    // background-color: $background;
    background-color: #00000055;
    width: $sidewidth;
    padding-top: 62px;
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 10;

    &.scrolled {
      padding-top: 0px;
      .sidebar-container {
        .brand {
          display: block;
        }
      }
    }
    .sidebar-container {
      padding: 1.5em 1em;
      color: white;
      .brand {
        display: none;
      }
    }
  }

  .main {
    flex: 1 1 auto;
    margin-left: $sidewidth;
  }
  // incase small screen
  &.small-screen {
    // background-color: turquoise;
    .sidebar {
      &:after {
        position: absolute;
        width: 100vh;
        height: 100vh;
        background-color: #00000088;

        top: 0;
        left: $sidewidth;
        content: ".";
      }
      &.hide {
        transform: translateX(-$sidewidth);
        &:after {
          display: none;
        }
      }
    }

    .main {
      margin-left: 0 !important;
    }
  }
}

.light-mode {
  background: radial-gradient(rgba(38, 188, 180, 0.141176), #fff);
}

.height-6 {
  height: $font-size-base;
}
.height-5 {
  height: $font-size-base * 1.25;
}
.height-4 {
  height: $font-size-base * 1.5;
}
.height-3 {
  height: $font-size-base * 1.75;
}
.height-2 {
  height: $font-size-base * 2;
}
.height-1 {
  height: $font-size-base * 2.5;
}

.btn-link {
  color: $brand;
  text-decoration: none;
  :hover,
  &:active,
  &:focus {
    color: $white;
    box-shadow: none;
  }
}

.btn-outline-primary {
  color: $brand;
  border-color: $brand;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: $brand;
    border-color: $brand;
    box-shadow: none;
  }
}

::selection {
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  background: $brand;
}

:focus {
  outline: none !important;
}

*::before *::after {
  box-sizing: border-box;
}

header {
  background-color: $background;
  z-index: 999;
  transition: 0.5s;
}

main {
  z-index: 1;
}

footer {
  text-align: right;
  z-index: 1;
  position: relative;
}

body {
  background-color: $background;
  color: $text;
  font-weight: 400;
  // font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  //   sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-family: "Roboto Condensed", sans-serif;
  font-feature-settings: "ss01" on, "ss02" on, "cv01" on, "cv03" on;
}

a {
  color: $text;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: $brand;
}

.dropdown-menu {
  border-radius: 0.5em;
  border: none;
  background: $background;
  color: $text;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04),
    0 16px 24px rgba(0, 0, 0, 0.04), 0 24px 32px rgba(0, 0, 0, 0.01);
}
.dropdown-item {
  padding: 0.25rem 0.5rem;
  color: $text;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $brand;
}

.bg-default {
  background-color: $background;
}

.text-brand {
  color: $brand;
}

.modal-content {
  background: $background;
  background-size: cover;
  border: none;
}

.bg-brand {
  background-color: $brand;
}

.modal-header {
  border-bottom: 0px;
}

.modal-footer {
  border-top: 0px;
}

.navbar-nav .nav-link {
  color: #fff;
}

.nav-link {
  color: #fff;
}

.number {
  font-family: "Inter Var", sans-serif;
  letter-spacing: -0.02em;
}

.logo-icon {
  height: 1.5em;
}

.btn {
  /* transform: scale(1); */
  transition: all 0.5s;
  color: $text !important;
}

.btn:hover {
  transition: all 0.5s;
  /* transform: scale(.95); */
  color: $text;
}

.btn:active {
  transition: all 0.5s;
  /* transform: scale(.95); */
}

.btn:focus {
  transition: all 0.5s;
  /* transform: scale(.95); */
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3;
}

.btn-soft-brand {
  color: $brand !important;
  background-color: #bb936533;
}

.btn-soft-info {
  background-color: rgba(243, 132, 30, 0.05);
  color: rgb(243, 183, 30);
}

.btn-soft-secondary {
  background-color: rgba(0, 0, 0, 0.05);
  color: $secondary;
}

.btn-primary {
  background-color: $brand;
  color: $text;
}

.nav-link.active,
.nav-link:hover {
  color: $brand-2;
}

.main-app {
  max-width: 420px;
  margin: 20px auto;
}

.bg-img-1 {
  background: $background;
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/bg-menu.jpg);
  background-size: auto;
  border: none;
  background-size: cover;
  /* background-blend-mode: color-dodge; */
}

.card {
  border-radius: 1em;
  color: $text;
  border: 1px solid #5c5c5c;
  background-color: $background;
  transition: all 1s;

  // padding: 2em;

  &:hover,
  &:focus {
    border: 1px solid #b2b4bb;
  }
  .card-header {
    border-bottom: none;
  }
}

.stacked-card {
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $background;
    background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/bg-menu.jpg);
    background-size: auto;
    border: 1px solid #5c5c5c;
    z-index: -1;
    border-radius: 1em;
  }

  &:before {
    top: -10px;
    left: 8%;
    width: 84%;
    height: 92%;
  }

  &:after {
    top: -6px;
    left: 4%;
    width: 92%;
    height: 92%;
  }
}

.buy-option {
  opacity: 0;
  transition: all 0.3s;
}

.card:hover .card-footer .buy-option {
  opacity: 1;
}

.nav-link {
  cursor: pointer;
}

.nav-pills .nav-link:hover {
  transition: all 0.5s;
}
.nav-pills {
  background: transparent;
  /* box-shadow: var(--box-shadow-inset); */
  border-radius: 10rem;
}

.navbar {
  padding: 1.2rem 1rem;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.token-icon {
  height: 4em;
}

.token-1em {
  height: 1em;
}

.form-control:focus {
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
}

/* INLINE LOADER */

.loader {
  height: 8px;
  width: 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */
}

.loader-box {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0px 2px;
  background-color: $brand;
  animation-name: fadeOutIn;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loader-box:nth-child(1) {
  animation-delay: 250ms;
} /* (1/2) * <animation-duration: 500ms */
.loader-box:nth-child(2) {
  animation-delay: 500ms;
} /* (2/2) * <animation-duration: 500ms */
.loader-box:nth-child(3) {
  animation-delay: 750ms;
} /* (3/2) * <animation-duration: 500ms */

@keyframes fadeOutIn {
  0% {
    background-color: $brand;
  }
  100% {
    background-color: #fff;
  }
}

.img-inline {
  height: 1em;
}

.brand-gradient {
  background-image: linear-gradient(45deg, $brand, $brand-50, $brand-800);
  background-size: 150% 150%;
  -webkit-animation: gradient 15s ease infinite;
  animation: gradient 15s ease infinite;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.brand-shadow {
  color: rgba(255, 255, 255, 0.96);
  text-shadow: 0 0 80px rgba(192, 219, 255, 0.5),
    0 0 32px rgba(65, 120, 255, 0.25);
}
.hero-img {
  width: 70%;
}
.parallax-effect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  color: white;
  border-radius: 50px;
  transform-style: preserve-3d;

  // &:hover {
  // background-color: $background;
  // }

  .inner-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateZ(60px);

    &:hover {
      color: $text !important;
      text-shadow: 0 0 20px rgba(192, 219, 255, 0.5);
      background-image: none;
    }
  }
}

@media (min-width: 768px) {
  .card-stack {
    display: grid;
    grid-auto-rows: 1fr;
  }

  .card-stack-item,
  .sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
  }
  .card-stack-item > .card {
    transition: all 0s;
    // background-color: #13202f;
    // -webkit-backdrop-filter: blur(20px) !important;
    // backdrop-filter: blur(20px) !important;

    // -webkit-backdrop-filter: none !important;
    // backdrop-filter: none !important;
    height: 100%;
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    margin-bottom: 5em;
  }
  .card-stack-item > .card > .row {
    height: inherit;
  }
}

.div-highlight {
  height: 1px;
  // box-shadow: 0px 0px 10px $brand-50;
  background-image: linear-gradient(90deg, #2ae6b400, #2ae6b577, #2ae6b400);
}

.bg-video {
  mix-blend-mode: screen;
  position: fixed;
  top: 0;
  right: 0;
}

.list-group-item {
  background: transparent;
  color: inherit;
}

.bg-none {
  background-color: transparent;
  box-shadow: none;
}

.timeline {
  border-left: 3px solid $brand;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  // background: rgba(114, 124, 245, 0.09);
  background-color: #13202f77;
  -webkit-backdrop-filter: blur(15px) !important;
  backdrop-filter: blur(15px) !important;
  margin-left: auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 70%;
}

@media (max-width: 767px) {
  .timeline {
    max-width: 98%;
    padding: 25px;
  }
}

.timeline h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline .event {
    padding-top: 30px;
  }
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline .event:after {
  -webkit-box-shadow: 0 0 0 3px $brand;
  box-shadow: 0 0 0 3px $brand;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline .event:after {
    left: -31.8px;
  }
}

.middle-info {
  display: flex;
  justify-content: space-between;

  margin: 1rem 0;
  .rate-info {
    align-items: center;
    display: flex;
    margin-right: 1rem;
  }
  .arrow-down {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }
}

.exchange-form {
  // border-radius: 0.5em;
  // border: 1px solid #edeef2;
  // background-color: #f7f8fa;
  // &:hover,
  // &:focus {
  //   border: 1px solid #ced0d9;
  // }

  .input-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 0.75rem;

    .input-token {
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 19px;
      font-weight: 500;
      // background-color: #000;
      // color: #fff;
      border-radius: 0.4em;
      box-shadow: none;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
      outline: none;
      // cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: none;
      height: 1.6em;
      width: initial;
      padding: 0 10px 0 0;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-right: 12px;
    }

    input.input-detail {
      color: $text;
      width: 0;
      position: relative;
      font-weight: 500;
      outline: none;
      border: none;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      background-color: transparent;
      font-size: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px;
      -webkit-appearance: textfield;
      text-align: right;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.5; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        opacity: 0.5;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        opacity: 0.5;
      }
    }
  }

  .balance {
    font-size: 0.75rem;
    line-height: 1rem;
    // padding: 0 1rem 1rem;
  }
}

.monospace {
  font-family: "Inter Var", monospace;
}

.tab {
  justify-self: center;
  background-color: $background;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px;
  border-radius: 0.75em;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04),
    0 16px 24px rgba(0, 0, 0, 0.04), 0 24px 32px rgba(0, 0, 0, 0.01);
  .tab-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    outline: none;
    cursor: pointer;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
    font-size: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    padding: 8px 12px;
    word-break: break-word;
    &.active {
      border-radius: 0.5em;
      font-weight: 600;

      background-color: #ffffff22;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.border-item {
  margin: 1rem 0;
  border-radius: 0.5rem;
  border: 1px solid #edeef222;
  background-color: #f7f8fa11;
  padding: 1rem;
  &:hover,
  &:focus {
    border: 1px solid #ced0d9;
    // cursor: pointer;
  }
}

.s-box {
  .s-form {
    display: flex;
    justify-content: space-between;
  }

  input.input-s {
    color: $text;
    width: 100%;
    // position: relative;
    font-weight: 500;
    outline: none;
    border: none;
    // -webkit-flex: 1 1 auto;
    // -ms-flex: 1 1 auto;
    // flex: 1 1 auto;
    background-color: transparent;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem 0 0 0;
    -webkit-appearance: textfield;
    // text-align: right;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5; /* Firefox */
    }
  }
}

.pointer {
  cursor: pointer;
}

.z-1 {
  z-index: -1;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
}

.nk-footer-top-corner {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  max-height: 250px;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: -1;
}

.card-fight {
  cursor: pointer;
  width: 230px;
  height: 333px;
  perspective: 1200px;
  border-radius: 20px;
  &.card-result {
    width: 130px;
    height: 180px;
    .content {
      .front,
      .back {
        border-radius: 10px;
      }
    }
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 1s;
    transform-style: preserve-3d;
    transform: rotateY(30deg);
    // border-radius: 20px;

    .front,
    .back {
      position: absolute;
      height: 100%;
      width: 100%;
      background: white;
      line-height: 300px;
      color: #03446a;
      text-align: center;
      font-size: 60px;
      border-radius: 20px;
      backface-visibility: hidden;
    }
    .back {
      background: #03446a;
      color: white;
      transform: rotateY(180deg);
    }
  }

  &:hover .content {
    animation: rotating 4s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotateY(30deg);
  }
  to {
    transform: rotateY(390deg);
  }
}

.text-c {
  color: #fff;
  text-shadow: 0 0 5px #0fa, 0 0 10px #0fa, 0 0 12px #0fa, 0 0 15px #0fa,
    0 0 20px #0fa;
}

.text-r {
  color: #fff;
  text-shadow: 0 0 5px rgb(0, 174, 255), 0 0 10px rgb(0, 174, 255),
    0 0 12px rgb(0, 174, 255), 0 0 15px rgb(0, 174, 255),
    0 0 20px rgb(0, 174, 255);
}

.text-sr {
  color: #fff;
  text-shadow: 0 0 5px #fe5, 0 0 10px #fe5, 0 0 12px #fe5, 0 0 15px #fe5,
    0 0 20px #fe5;
}

.text-ssr {
  color: #fff;
  text-shadow: 0 0 5px #e0e, 0 0 10px #e0e, 0 0 12px #e0e, 0 0 15px #e0e,
    0 0 20px #e0e;
}

.hamber {
  display: inline-block;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  width: 22px;
  height: 2px;
  border-radius: 4px;
  background-color: rgb(116, 116, 116);
  margin: 6px 0;
  transition: 0.4s;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-3px, 6px);
  transform: rotate(-45deg) translate(-3px, 6px);
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -8px);
  transform: rotate(45deg) translate(-5px, -8px);
}

.title-description {
  max-width: 500px;
  color: $secondary;
}

.chest-img {
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  transition: all 0.2s;
  &.hide {
    max-width: 130px;
  }
}

.img-result {
  max-width: 100%;
}
.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
}
