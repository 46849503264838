/* /* @font-face {
  font-family: "Lexend";
  font-weight: bold;
  src: local("Lexend"),
    url(../font/Lexend-Bold.ttf) format("truetype");
} */

@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: "Regular";
  src: url(../font/Inter-roman.var.woff2)
      format("woff2 supports variations(gvar)"),
    url(../font/Inter-roman.var.woff2)
      format("woff2-variations"),
    url(../font/Inter-roman.var.woff2) format("woff2");
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'), url(../font/RobotoCondensed-Regular.ttf) format('truetype');
}
/* 
@font-face {
  font-family: "Inter Var";
  font-weight: normal;
  src: local("Inter Var"), url(../font/Inter-roman.var.woff2) format("woff2");
} */